import React, { useEffect, useState } from "react";
import Loading from "../../../../shared/components/common/Loading";
import withAppLayout from "../../../../shared/components/withAppLayout";
import Wizard from "@amzn/awsui-components-react/polaris/wizard";
import { generateI18nStrings } from "./util/i18n";
import i18n from "../../../i18n";
import Step from "./Step";
import { OptionsProvider } from "../../../util/context/OptionsContext";
import { StepsContainerProvider } from "../../../util/context/StepsContainerContext";
import { TemplateProvider } from "../../../util/context/TemplateContext";
import { DataSubmissionFactory } from "../../../util/services/data/dataFactory";
import {
  checkStatusSuccess,
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../shared/util/services/data/DataService";
import { formatData } from "../../../../shared/util/adapter/toApi/adapter";
import Link from "@amzn/awsui-components-react/polaris/link";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { filterFields, getTemplate, schema } from "../../../config/schema";
import {
  createFieldSchemaObject,
  createJsonSchemaObject,
} from "../../../../shared/util/schema/util";
import { createValidator } from "../../../../shared/util/validation/validator";
import {
  getSectionConstant,
  SECTION,
  SECTION_CONSTANT_TYPE,
  SECTION_NAME,
} from "../../../../shared/util/constants/section";
import { getSfdcLandingPageLink } from "../../../util/common/helper";
import { ValidationFactory } from "../../../../shared/components/FundRequest/StepsContainer/ValidationFactory";
import { getFundRequest } from "../../../util/services/data/FundRequestService";
import {
  setErrorMessage,
  setInfoMessage,
  setSuccessMessage,
} from "../../../../shared/util/common/helper";
import { getBreadcrumbs } from "../../../../shared/util/common/getBreadcrumbs";
import useHelpText from "../../../../shared/util/hooks/useHelpText";
import { HelperPanel } from "../../../../shared/components/FundRequest/Help/HelperPanel";
import { convertSMPtoMAP } from "../../../../shared/util/common/convertSMPtoMAP";
import { generateMultiSectionKey } from "../../../../shared/util/common/generateMultiSectionKey";
import PropTypes from "prop-types";
import { isFeatureEnabled } from "../../../../shared/util/services/features/FeatureFlagsService";
import { FEATURE_FLAG_ENUMS } from "../../../../shared/util/constants/featureFlagValues";
import { mpe_records_do_exists } from "./util/util";

const StepsContainer = ({ setHideBanner }) => {
  const { id } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingNextStep, setIsLoadingNextStep] = useState(false);
  const [i18nStrings, setI18nStrings] = useState(
    generateI18nStrings(i18n.t("wizard.common.cancel"))
  );
  const [data, setData] = useState({});
  const [template, setTemplate] = useState({});
  const [fieldToIdMap, setFieldToIdMap] = useState({});
  const [errors, setErrors] = useState({});
  const [validators, setValidators] = useState({});
  const [onCancel, setOnCancel] = useState(
    () => () => window.location.replace(getSfdcLandingPageLink())
  );
  const [notifications, setNotifications] = useState([]);

  const [activeIndex, setActiveIndex] = React.useState(0);

  const {
    isHelperPanelOpen,
    helperPanelContent,
    setIsHelperPanelOpen,
    showHelperPanel,
  } = useHelpText();

  useEffect(async () => {
    if (state && state.origin === "Recall") {
      setSuccessMessage(setNotifications, "Recalled", state.successMessage);
    }

    try {
      const response = await retryApiCall({ callApi: getFundRequest(id) });

      //TODO: Add failure condition
      if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
        setErrorMessage({
          setNotificationsItems: setNotifications,
          content: response.message,
          status: response.status,
        });
        setIsLoading(false);
        return;
      }

      const {
        fundingTemplate: { fundingType, migrationPhase, program, subProgram },
      } = response;
      const newTemplate = getTemplate({ fundingType, migrationPhase, program });
      const sections = Object.keys(newTemplate).reduce(
        (previous, templateName) => {
          const sections = newTemplate[templateName].sections.map(
            (sectionName) => {
              return {
                ...schema[sectionName],
                fields: filterFields({
                  section: schema[sectionName],
                  program,
                  subProgram,
                }),
              };
            }
          );
          return [...previous, ...sections];
        },
        []
      );
      const fieldsSchemas = [];
      const createdSchemas = [];
      for (let section of sections) {
        for (let field of section.fields) {
          if (!field.auto && !createdSchemas.includes(field.name)) {
            const attributes = {
              component: field.component,
              fieldName: field.name,
              type: field.type || undefined,
              pattern: field.pattern || undefined,
              errorMessage: field.errorMessage || undefined,
            };
            createdSchemas.push(field.name);
            fieldsSchemas.push(createFieldSchemaObject(attributes));
          }
        }
      }

      const sectionSchemas = sections.map((section) =>
        createJsonSchemaObject({
          sectionName: section.name,
          fields: section.fields,
          program: program,
        })
      );

      setTemplate(newTemplate);
      setData({
        fundingType,
        program,
        subProgram,
        migrationPhase,
        annualRunRateUnits: response?.fundingTemplate?.annualRunRate?.units,
        fundRequestId: id,
        ...response,
      });
      setIsLoading(false);
      setValidators(createValidator([...sectionSchemas, ...fieldsSchemas]));
      setOnCancel(() => () => {
        history.push(`/fund-request/select-program?template=${program}`);
      });
    } catch (e) {
      console.error(e);
    }
  }, [id]);

  useEffect(() => {
    document.querySelectorAll("button").forEach((button) => {
      if (
        button.innerText === "Hidden" ||
        button.innerText === i18n.t("wizard.common.save_later")
      ) {
        button.parentNode.style = "display: none";

        if (activeIndex + 1 === template.length) {
          button.innerText = i18n.t("wizard.common.save_later");
          button.parentNode.style = "display: block";
        }
      }
    });
  }, [activeIndex]);

  const steps = Object.keys(template).map((stepName) => {
    const step = template[stepName];
    return {
      title: `${step.title}`,
      //TODO: Should this just show info?
      info: (
        <Link
          variant="Info"
          onFollow={() =>
            showHelperPanel({ title: step.title, keys: [stepName], open: true })
          }
        >
          Info
        </Link>
      ),
      content: (
        <Step
          activeIndex={activeIndex}
          data={data}
          fieldToIdMap={fieldToIdMap}
          setFieldToIdMap={setFieldToIdMap}
          template={template}
          setData={setData}
          errors={errors}
          setErrors={setErrors}
          validators={validators}
          setNotificationItems={setNotifications}
          showHelperPanel={showHelperPanel}
        />
      ),
    };
  });

  const onNavigate = async ({ detail }) => {
    setNotifications([]);
    const mpe_id_and_arr_validation_for_map_is_enabled = await isFeatureEnabled(
      FEATURE_FLAG_ENUMS.MPE_AND_ARR_VALIDATION_FOR_MAP_OPPORTUNITY
    );
    if (
      activeIndex + 1 === Object.keys(template).length &&
      detail.reason === "previous"
    ) {
      return;
    }

    if (detail.reason === "next") {
      setIsLoadingNextStep(true);

      const currentStepName = Object.keys(template)[activeIndex];
      const currentStep = template[currentStepName];
      const sections = [...currentStep.sections]; // Copies sections array in memory; Ensures the template is not permanently updated by a change in the sections array

      let validated = true;
      let validationErrors = {};
      let submissionData = {};

      if (
        currentStep.title === SECTION.PROJECT_INFORMATION &&
        Object.keys(fieldToIdMap).includes(SECTION_NAME.PUBLIC_SECTOR_DETAILS)
      ) {
        sections.push(SECTION.PUBLIC_SECTOR_DETAILS); // Allows public-sector API call
      }

      // Format, and validate data
      for (let sectionTitle of sections) {
        // Skip cash claims, since the data and validation is part of cash request
        if (sectionTitle === SECTION.CASH_CLAIM_PLANS) continue;

        const sectionName = getSectionConstant({
          type: SECTION_CONSTANT_TYPE.NAME,
          value: sectionTitle,
        });

        const formattedData = formatData(sectionName)({
          step: currentStep,
          stepName: sectionName,
          stepTitle: sectionTitle,
          data,
          fieldMap: fieldToIdMap,
          schema,
          filterFields,
        });

        if (
          currentStep.title === SECTION.CREDIT_REQUEST_DETAILS &&
          data.program === "POC"
        ) {
          formattedData["requestedCreditAmount"] = {
            ...formattedData["requestedCreditAmountEditable"],
          };
          delete formattedData["requestedCreditAmountEditable"];
        }

        //Validate formatted data
        const { errors, valid } = ValidationFactory(sectionTitle)({
          validators,
          sectionName,
          data: formattedData,
          program: data.program,
        });
        validated = valid && validated;

        submissionData = {
          ...submissionData,
          [sectionTitle]: { ...formattedData },
        };

        validationErrors = {
          ...validationErrors,
          ...errors,
        };

        if (sectionTitle === SECTION.OPPORTUNITY_INFORMATION) {
          if (!validated) {
            setErrorMessage({
              setNotificationsItems: setNotifications,
              content: validationErrors[sectionName],
            });
            setIsLoadingNextStep(false);
          }
        }
      }

      let submitted = validated;
      // Attempt submission and capture errors.
      if (validated) {
        for (let sectionTitle of Object.keys(submissionData)) {
          const sectionName = getSectionConstant({
            type: SECTION_CONSTANT_TYPE.NAME,
            value: sectionTitle,
          });

          const submissionResponse = await retryApiCall({
            callApi: DataSubmissionFactory({ title: sectionTitle })(
              submissionData[sectionTitle]
            ),
          });

          const success = !hasError(submissionResponse);

          if (!success) {
            //Handle error
            const responseBodyKeys = Object.keys(submissionResponse);

            if (
              responseBodyKeys.length > 0 &&
              responseBodyKeys.includes("errors") &&
              typeof submissionResponse["errors"] === "object"
            ) {
              if (
                Object.keys(submissionResponse["errors"]).includes("cashClaims")
              ) {
                const cashClaimKeys = Object.keys(
                  submissionResponse["errors"]["cashClaims"]
                );
                cashClaimKeys.map((cashClaimKey) => {
                  const index = cashClaimKey[cashClaimKey.length - 1];
                  const cashClaimSectionName = generateMultiSectionKey({
                    index,
                    sectionName: SECTION_NAME.CASH_CLAIM_PLANS,
                  });

                  validationErrors = {
                    ...validationErrors,
                    [cashClaimSectionName]: {
                      ...submissionResponse["errors"]["cashClaims"][
                        cashClaimKey
                      ],
                    },
                  };
                });
              } else {
                validationErrors = {
                  ...validationErrors,
                  [sectionName]: {
                    ...submissionResponse["errors"],
                    ...validationErrors[sectionName],
                  },
                };
              }
            } else {
              setErrorMessage({
                setNotificationsItems: setNotifications,
                content:
                  submissionResponse &&
                  (submissionResponse.message ||
                    JSON.stringify(submissionResponse)),
                status: submissionResponse.status,
              });
            }
            submitted = success && submitted;
          }
          if (
            success &&
            mpe_id_and_arr_validation_for_map_is_enabled &&
            sectionName === SECTION_NAME.OPPORTUNITY_INFORMATION &&
            mpe_records_do_exists(submissionResponse) === false
          ) {
            setInfoMessage({
              setNotificationsItems: setNotifications,
              header: "Caution:",
              content:
                "Please expect some processing delays and try to work with respective PSM.",
            });
          }
        }
      }
      setIsLoadingNextStep(false);

      //Handling errors and preventing continuation.
      if (!submitted) {
        const newErrors = {};
        Object.keys(validationErrors).forEach((sectionName) => {
          Object.keys(validationErrors[sectionName]).forEach((fieldName) => {
            let errorId = fieldToIdMap[sectionName][fieldName];
            newErrors[errorId] = validationErrors[sectionName][fieldName];
          });
        });

        setErrors({ ...errors, ...newErrors });
        return;
      }
    }

    if (detail.requestedStepIndex === 0) {
      setOnCancel(() => () => {
        history.push(
          `/fund-request/select-program?template=${data["program"]}`
        );
      });
      setI18nStrings(generateI18nStrings(i18n.t("wizard.common.cancel")));
    } else {
      setI18nStrings(generateI18nStrings(i18n.t("wizard.common.previous")));
      setOnCancel(
        () => () =>
          onNavigate({
            detail: { requestedStepIndex: detail.requestedStepIndex - 1 },
          })
      );
    }
    setActiveIndex(detail.requestedStepIndex);
  };

  return isLoading ? (
    <Loading />
  ) : (
    withAppLayout({
      component: (
        <StepsContainerProvider
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          <TemplateProvider
            providedTemplate={template}
            setTemplate={setTemplate}
          >
            <OptionsProvider data={data}>
              <Wizard
                i18nStrings={i18nStrings}
                isLoadingNextStep={isLoadingNextStep}
                onCancel={() => onCancel()}
                onNavigate={onNavigate}
                onSubmit={async () => {
                  setIsLoadingNextStep(true);
                  try {
                    const response = await retryApiCall({
                      callApi: DataSubmissionFactory({})(),
                    });
                    if (
                      response &&
                      response.status &&
                      !checkStatusSuccess(response.status)
                    ) {
                      let responseBody = response;

                      if (response.body) {
                        responseBody = await response.json();
                      }

                      // Check if we were returned errors and set the errors to the field.
                      if (
                        responseBody &&
                        typeof responseBody === "object" &&
                        (Object.keys(responseBody).includes("errors") ||
                          Object.keys(responseBody).includes("errorType"))
                      ) {
                        setErrorMessage({
                          setNotificationsItems: setNotifications,
                          content: response.message,
                          status: response.status,
                        });
                        setIsLoadingNextStep(false);
                        return;
                      }
                    }

                    history.push(`/fund-request/${id}/review`, {
                      origin: "StepsContainer",
                    });
                    setHideBanner(false);
                  } catch (err) {
                    setIsLoadingNextStep(false);
                    console.error(err);
                  }
                }}
                toolsOpen={false}
                activeStepIndex={activeIndex}
                steps={steps}
                className="step-container-wizard"
              />
            </OptionsProvider>
          </TemplateProvider>
        </StepsContainerProvider>
      ),
      breadcrumbs: getBreadcrumbs({
        text: `Apply for the ${convertSMPtoMAP(data.subProgram)}`,
      }),
      toolsContent: <HelperPanel content={helperPanelContent} />,
      isToolsOpen: isHelperPanelOpen,
      onToolsChange: ({ detail }) => {
        setIsHelperPanelOpen(detail.open);
      },
      notificationsItems: notifications,
    })()
  );
};

StepsContainer.propTypes = {
  setHideBanner: PropTypes.func,
};

export default StepsContainer;
